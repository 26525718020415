<template>
  <div class="preevent flex justify-between items-start">
    <div class="flex-1 pb-6" v-for="(data, index) in eventData" :key="'event-' + index">
      <div class="text-center py-6 px-4 text-white" :style="{ backgroundColor: data.classValue }">
        <p class="text-2xl font-bold">{{ data.name }}</p>
        <p class="text-xs">{{ data.shortDescriptionText }}</p>
        <p class="text-xs mt-1">
          <span class="inline-block border-r border-white pr-2 mr-2 leading-normal">{{ data.schedule }}</span>
          {{ data.scheduleFrom }} - {{ data.scheduleTo }} WIB
        </p>
      </div>
      <div class="bg-white py-6 px-5">
        <p class="list" v-html="data.note"></p>
      </div>
      <Button buttonText="RSVP" @action="rsvp()" />
    </div>
  </div>
</template>
<script>
import { formatFullDate } from '@/utils'
export default {
  filters: {
    formatFullDate
  },
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  props: {
    eventData: {
      type: Array
    }
  },
  methods: {
    rsvp() {
      this.$emit('rsvp')
    }
  }
}
</script>
<style lang="scss" scoped>
.list {
  min-height: 230px;
  &::v-deep(ul) {
    padding: 0;
    margin: 0;
    padding-left: 16px;
    text-align: left;
    li {
      padding-left: 16px;
      margin-bottom: 16px;
      font-size: 14px;
      &::before {
        content: '\2022';
        color: #f7931e;
        font-weight: bold;
        display: inline-block;
        vertical-align: text-bottom;
        width: 32px;
        margin-left: -32px;
        font-size: 36px;
      }
    }
  }
}
</style>
